import { DialogActions, Checkbox, Button, Typography, Stack, Card, CardContent, CardMedia, Dialog, DialogTitle, DialogContent, IconButton, Grid, Box } from "@mui/material";
import { useWalletInterface } from "../services/wallets/useWalletInterface";
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from "react";
import { MirrorNodeClient, NfthingyMetadata } from "../services/wallets/mirrorNodeClient";
import { appConfig } from "../config";
import { ConnectButton } from "../components/ConnectButton";
import logoIntro from '../assets/logoIntro.png';
import Background from '../assets/background.jpg';
import AuthContext from "../contexts/AuthContext";
import axios from 'axios';
import xlogo from '../assets/xlogo.png';
import ProgressModal from '../components/ProgressModal';
import { useTheme } from '@mui/material/styles';
import NftMetadataCard from '../components/NftMetadataCard';

export default function Home() {
  const theme = useTheme();
  const { walletInterface, accountId } = useWalletInterface();
  const [NftData, setNftData] = useState<NfthingyMetadata[]>([]);
  const [currentNftIndex, setCurrentNftIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [twitterData, setTwitterData] = useState<any | null>(null);
  const { login, authToken, logout, isTokenValid } = useContext(AuthContext);
  const [metadata, setMetadata] = useState<any | null>(null);

  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [debugMode, setDebugMode] = useState(true);

  const nfthingyTokenId = process.env.REACT_APP_NFTHINGY_TOKEN_ID || "0.0.4506312";
  const serverUrl = process.env.REACT_APP_SERVER_URL! || "https://nfthingy.pestnet.wethemouth.com";

  useEffect(() => {
    if (accountId) {
      fetch(`${serverUrl}/api/user`, {
        credentials: 'include'
      })
        .then(res => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then(data => setTwitterData(data))
        .catch(err => {
          console.error('Error fetching user data:', err);
        });
    }
  }, [accountId, serverUrl]);

  useEffect(() => {
    if (accountId) {
      const mirrorNodeClient = new MirrorNodeClient(appConfig.networks.testnet); //this needs to be a NETWORK variable
      mirrorNodeClient.getNfthingyData(accountId, nfthingyTokenId)
        .then(nftData => {
          setNftData(nftData);
        })
        .catch(error => console.error(error));
    }
  }, [accountId, nfthingyTokenId]);

  useEffect(() => {
    if (!isTokenValid()) {
      console.log('Token is invalid or expired. Please re-authenticate.');
    }
  }, [authToken, isTokenValid]);

  const handleConnectTwitter = async () => {
    try {
      const token = await walletInterface?.signAndVerifyMessage();
      if (token) {
        login(token);
        window.location.href = `${serverUrl}/auth/twitter`;
      } else {
        console.log("Verification failed (Twitter auth flow)")
      }
    } catch (error) {
      console.error('Error during Twitter auth flow:', error);
    }
  };

  const handleNfthingyMint = async () => {
    try {
      const transactionId = await walletInterface?.mintNfthingy();
      if (transactionId) {
        console.log("Transaction successful, ID:", transactionId);
      } else {
        console.error("Transaction failed");
      }
    } catch (error) {
      console.error("Error during transaction:", error);
    }
  };

  const handleAddTwitterDataToNfthingy = async (isPublic: boolean) => {
    try {
      if (!metadata) {
        throw new Error("No metadata found.");
      }
      setIsLoading(true);
      setProgressMessage('Adding Twitter data to NFThingy...');
      setProgressModalOpen(true);

      const timestamp = new Date().toISOString(); // Add timestamp

      const message = await walletInterface?.addAuthTwitter(metadata.serialNumber, isPublic, timestamp);

      setIsLoading(false);
      if (message) {
        setProgressMessage("Twitter data added to NFThingy successfully");
      } else {
        setProgressMessage("Failed to add Twitter data to NFThingy");
      }
    } catch (error: any) {
      setIsLoading(false);
      setProgressMessage(`Error adding Twitter data to NFThingy: ${error.message}`);
      console.error('Error adding Twitter data to NFThingy:', error);
    }
  };

  const handleRemoveStuff = async (platform: string) => {
    try {
      setIsLoading(true);
      setProgressMessage('Removing data from NFThingy...');
      setProgressModalOpen(true);

      const response = await walletInterface?.removeStuff(metadata.serialNumber, platform);

      if (response.status === 200) {
        setProgressMessage("Data removed from NFThingy successfully");
        // Update the local state with the new metadata from the server
        setMetadata(response.data.updatedMetadata);
        // Also update the NftData array
        setNftData(prevData => {
          const newData = [...prevData];
          newData[currentNftIndex] = response.data.updatedMetadata;
          return newData;
        });
      } else {
        setProgressMessage("Failed to remove data from NFThingy");
      }
    } catch (error: any) {
      setProgressMessage(`Error removing data from NFThingy: ${error.message}`);
      console.error('Error removing data from NFThingy:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenUpdateDialog = () => setOpenUpdateDialog(true);
  const handleCloseUpdateDialog = () => setOpenUpdateDialog(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleSelectNft = (index: number) => {
    setCurrentNftIndex(index);
    handleCloseModal();
  };

  useEffect(() => {
    if (NftData.length > 0) {
      setMetadata(NftData[currentNftIndex]);
    }
  }, [currentNftIndex, NftData]);

  return (
    accountId ? (
      <Stack alignItems="center" spacing={4}>
        <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
          <DialogTitle>Add stuff to NFThingy</DialogTitle>
          <DialogContent>
            <Typography>Do you want to make your data public? This allows for anyone to access your username, twitter Id and photo on your NFThingy. Standard is Private.</Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <Checkbox
                checked={isPublic}
                onChange={() => setIsPublic(!isPublic)}
                inputProps={{ 'aria-label': 'public or private checkbox' }}
              />
              <Typography>{"Yes, make it publicly visible."}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdateDialog}>Cancel</Button>
            <Button
              onClick={() => {
                handleAddTwitterDataToNfthingy(isPublic);
                handleCloseUpdateDialog();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {NftData.length > 0 ? (
          <Card sx={{ maxWidth: 600, margin: 'auto', padding: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, boxShadow: theme.shadows[4] }}>
            <CardMedia component="img" sx={{ width: { xs: '100%', md: '60%' }, marginRight: { xs: 0, md: 3 }, marginBottom: { xs: 2, md: 0 } }} image={NftData[currentNftIndex].image} alt={NftData[currentNftIndex].name} />
            <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {NftData[currentNftIndex].name}
                </Typography>
                <Typography variant="body2" component="p">
                  {NftData[currentNftIndex].description}
                </Typography>
              </CardContent>
              <Box display="flex" flexDirection="column" justifyContent="flex-end" mb={2}>
                <Button variant="outlined" onClick={handleOpenModal} sx={{ mb: 1, color: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}>
                  Select another one
                </Button>
                <Button variant="outlined" onClick={handleNfthingyMint} sx={{ color: theme.palette.text.secondary, borderColor: theme.palette.text.secondary }}>
                  Mint new NFTThingy
                </Button>
              </Box>
            </Box>
          </Card>
        ) : (
          <Typography variant="h6" align="center">
            <div>HELLO THERE :)</div>
            <p>YOU'RE GONNA NEED AN NFTHINGY TO ADD STUFF TO.</p>
            <Button variant="contained" color="primary" onClick={handleNfthingyMint}>
              Mint NFThingy
            </Button>
          </Typography>
        )}

        {/* NFT selection modal */}
        <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
          <DialogTitle>
            Select an NFThingy
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {NftData.map((nft, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card onClick={() => handleSelectNft(index)} sx={{ cursor: 'pointer', boxShadow: theme.shadows[4], borderRadius: theme.shape.borderRadius }}>
                    <CardMedia component="img" image={nft.image} alt={nft.name} />
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {nft.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>

        {NftData.length > 0 && (
          <Grid item xs={12} md={8} sx={{ width: '100%', maxWidth: 600 }}> 
            <Typography variant="h6" align="center" mt={4} mb={2}>
              NFThingy's #{NftData[currentNftIndex].serialNumber} stuff
            </Typography>
            <Box mb={2} sx={{ width: '100%' }}>
              <NftMetadataCard metadata={NftData[currentNftIndex]} onRemove={handleRemoveStuff} />
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={8} sx={{ width: '100%', maxWidth: 600 }}>
          <Typography variant="h6" align="center" mt={4} mb={2}>
            Authentications
          </Typography>

          <Box mb={2} sx={{ width: '100%' }}>
            {twitterData ? (
              <Card sx={{ 
                width: '100%',
                maxWidth: 600, 
                margin: 'auto', 
                padding: 2, 
                display: 'flex', 
                boxShadow: theme.shadows[4],
                borderRadius: '4px',
                overflow: 'hidden',
              }}>
                <CardMedia 
                  component="img" 
                  sx={{ width: 100, height: 100, objectFit: 'contain' }} 
                  image={twitterData.photos[0].value} 
                  alt="Twitter Profile" 
                />
                <CardContent>
                  <Typography variant="body1">
                    <strong>@{twitterData.username}</strong>
                  </Typography>
                  <Typography variant="body1">
                    <strong>Display Name:</strong> {twitterData.displayName}
                  </Typography>
                  
                  <Button 
                    variant="outlined" 
                    onClick={handleOpenUpdateDialog}
                    sx={{ 
                      mt: 2,
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    ADD TO NFTHINGY
                  </Button>
                </CardContent>
              </Card>
            ) : (
              <Card sx={{ 
                width: '100%',
                maxWidth: 600, 
                margin: 'auto', 
                padding: 2, 
                display: 'flex', 
                boxShadow: theme.shadows[4],
                borderRadius: '4px',
                overflow: 'hidden',
              }}>
                <CardMedia 
                  component="img" 
                  sx={{ width: 100, height: 100, objectFit: 'contain' }} 
                  image={xlogo} 
                  alt="X Profile" 
                />
                <CardContent sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  flexGrow: 1 // This will make the content take up the remaining space
                }}>
                  <Button 
                    variant="outlined" 
                    onClick={handleConnectTwitter}
                    sx={{ 
                      color: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    Connect to X
                  </Button>
                </CardContent>
              </Card>
            )}
          </Box>
        </Grid>

        {debugMode && (
          <Typography variant="h6" align="center" mt={4}>
            DEBUG MODE - Connected 
            <Box display="flex" flexDirection="column" alignItems="center">
              <Button onClick={() => {}} sx={{ mt: 2 }}>Authenticate</Button>
              <Button onClick={() => {}} sx={{ mt: 2 }}>Un-authenticate</Button>
              <Button onClick={handleConnectTwitter} sx={{ mt: 2 }}>Connect to Twitter (when already authenticated)</Button>
              <Button onClick={() => {}} sx={{ mt: 2 }}>Clear Data</Button>
            </Box>
          </Typography>
        )}

        <ProgressModal 
          open={progressModalOpen} 
          onClose={() => setProgressModalOpen(false)} 
          message={progressMessage} 
          loading={isLoading} 
        />
      </Stack>
    ) : (
      <Box sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <Box textAlign="center">
          <img src={logoIntro} alt="NFTThingy Logo" style={{ width: '400px', marginBottom: '20px' }} />
          <ConnectButton />
        </Box>
      </Box>
    )
  );
}
