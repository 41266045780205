import { NetworkConfigs } from "./type"

export const networkConfig: NetworkConfigs = {
  testnet: {
    network: "testnet",
    jsonRpcUrl: "https://testnet.hashio.io/api",
    mirrorNodeUrl: "https://testnet.mirrornode.hedera.com",
    chainId: "0x128",
  },
  mainnet: {
    network: "mainnet",
    jsonRpcUrl: "https://mainnet.hashio.io/api", // replace with the actual mainnet RPC URL
    mirrorNodeUrl: "https://mainnet.mirrornode.hedera.com",
    chainId: "0x127", // replace with the actual mainnet chain ID
  }
}