import { ReactNode } from "react"
// import { MetamaskContextProvider } from "../../contexts/MetamaskContext"
import { WalletConnectContextProvider } from "../../contexts/WalletConnectContext"
// import { MetaMaskClient } from "./metamask/metamaskClient"
import { WalletConnectClient } from "./walletconnect/walletConnectClient"

// export const AllWalletsProvider = (props: {
//   children: ReactNode | undefined
// }) => {
//   return (
//     <MetamaskContextProvider>
//       <WalletConnectContextProvider>
//         <MetaMaskClient />
//         <WalletConnectClient />
//         {props.children}
//       </WalletConnectContextProvider>
//     </MetamaskContextProvider>
//   )
// }

export const AllWalletsProvider = (props: {
  children: ReactNode | undefined
}) => {
  return (
      <WalletConnectContextProvider>
        <WalletConnectClient />
        {props.children}
      </WalletConnectContextProvider>
  )
}