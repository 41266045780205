import { Button, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import { WalletSelectionDialog } from './WalletSelectionDialog';
import connect from "../assets/connect.png";
import connectHover from "../assets/connectHover.png";



export const ConnectButton = () => {
    const [open, setOpen] = useState(false);
    const { accountId, walletInterface } = useWalletInterface();



     const handleConnect = async () => {
    if (accountId) {
      walletInterface.disconnect();
    } else {
      setOpen(true);
    }
  };
  
    return (
      <Box>
        <Button
            onClick={handleConnect}
            sx={{
              textTransform: 'none',
              borderRadius: '9.7px',
              padding: 0,
              border: 'none',
              backgroundImage: `url(${connect})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '200px',
              height: '57.89px',
              boxShadow: '2px 2px 5px 0 rgba(0,0,0,0.7)',
              '&:hover': {
                backgroundImage: `url(${connectHover})`,
                boxShadow: 'inset 0px 10px 25px 0 rgba(0,0,0,0.5)',
              }
            }}
          >
          </Button>
        
          <WalletSelectionDialog open={open} setOpen={setOpen} onClose={() => setOpen(false)} />

      </Box>
    );
  };
  
  