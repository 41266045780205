import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import xLogo from '../assets/xlogo.png';
import waLogo from '../assets/walogo.png';

interface StuffData {
  platform: string;
  private: boolean;
  id?: string;
  name?: string;
  profileImageUrl?: string;
  username?: string;
  timestamp?: string;
}

interface NftMetadataCardProps {
  metadata: {
    name: string;
    description: string;
    image: string;
    properties?: {
      stuff?: StuffData[];
    };
  };
  onRemove: (platform: string) => void;
}

const platformLogos: { [key: string]: string } = {
    X: xLogo,
    WhatsApp: waLogo    // Add more platforms and their corresponding logos here
  };

const NftMetadataCard: React.FC<NftMetadataCardProps> = ({ metadata, onRemove }) => {
  const theme = useTheme();
  const stuffData = metadata.properties?.stuff;

  const calculateRenewalDate = (timestamp: string) => {
    const date = new Date(timestamp);
    date.setFullYear(date.getFullYear() + 1);
    return date.toDateString();
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: 'auto' }}>
      {stuffData && stuffData.length > 0 ? (
        stuffData.map((item, index) => (
          <Card key={index} sx={{ 
            width: '100%',
            maxWidth: 600, 
            margin: 'auto', 
            marginBottom: 2,
            padding: 2, 
            display: 'flex', 
            boxShadow: theme.shadows[4],
            borderRadius: '4px',
            overflow: 'hidden',
          }}>
            <CardMedia 
              component="img" 
              sx={{ width: 100, height: 100, objectFit: 'contain' }} 
              image={item.profileImageUrl || platformLogos[item.platform] || '../assets/defaultImage.png'} 
              alt={`${item.platform} Profile`} 
            />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="body1">
                <strong>Platform:</strong> {item.platform}
              </Typography>
              {item.private ? (
                <Typography variant="body1">
                  This {item.platform} profile is set to private.
                </Typography>
              ) : (
                <>
                  {item.name && (
                    <Typography variant="body1">
                      <strong>Name:</strong> {item.name}
                    </Typography>
                  )}
                  {item.username && (
                    <Typography variant="body1">
                      <strong>Username:</strong> @{item.username}
                    </Typography>
                  )}
                  {item.timestamp && (
                    <Typography variant="body1">
                      <strong>Renewal Date:</strong> {calculateRenewalDate(item.timestamp)}
                    </Typography>
                  )}
                </>
              )}
              <Button variant="outlined" color="secondary" onClick={() => onRemove(item.platform)}>
                Remove from Stuff
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Card sx={{ 
          width: '100%',
          maxWidth: 600, 
          margin: 'auto', 
          padding: 2, 
          boxShadow: theme.shadows[4],
          borderRadius: '4px',
          overflow: 'hidden',
        }}>
          <CardContent>
            <Typography variant="body1" align="center">
              No stuff found in this NFThingy.
            </Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default NftMetadataCard;