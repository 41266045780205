import { AppBar, Button, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import NfthingyLogo from "../assets/logo.png";
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import { WalletSelectionDialog } from './WalletSelectionDialog';
import Background from "../assets/background.jpg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function NavBar() {
  const [open, setOpen] = useState(false);
  const { accountId, walletInterface } = useWalletInterface();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConnect = async () => {
    if (accountId) {
      walletInterface.disconnect();
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (accountId) {
      setOpen(false);
    }
  }, [accountId]);

  // No navbar if not connected
  if (!accountId) {
    return null;
  }

  // If connected return navbar
  return (
    <AppBar position='static' sx={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'top', paddingBottom: '0', boxShadow: 'none' }}>
      <Toolbar>
        <img src={NfthingyLogo} alt='The NFThingy logo' className='nfthingyLogoImg' />
        
        <Box sx={{ flexGrow: 1 }} /> {/* This pushes the button to the right */}
        
        <IconButton onClick={handleMenuClick} style={{ textTransform: 'none', borderRadius: '8px', padding: '3px 15px', border: '1px solid #000', width: '150px', backgroundColor: 'transparent' }}>
          <Typography style={{ fontWeight: 'normal', color: '#000' }}>{accountId}</Typography>
          {anchorEl ? <ExpandLessIcon style={{ color: '#000' }} /> : <ExpandMoreIcon style={{ color: '#000' }} />}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              borderRadius: '8px',
              border: '1px solid #000',
              padding: '0px',
              backgroundColor: 'transparent',
              width: '150px',
              marginTop: '-10px',
              borderTop: '0px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              boxShadow: '0px 0px 0px 0 rgba(0,0,0,0.7)'
            },
          }}
        >
          <MenuItem onClick={handleConnect} style={{ fontWeight: 'normal', color: '#000', fontSize: '10px', textDecoration: 'underline', textAlign: 'center' }}>
            Disconnect Wallet
          </MenuItem>
        </Menu>
      </Toolbar>
      <WalletSelectionDialog open={open} setOpen={setOpen} onClose={() => setOpen(false)} />
    </AppBar>
  );
}