import { WalletConnectContext } from "../../../contexts/WalletConnectContext";
import { useCallback, useContext, useEffect } from 'react';
import { WalletInterface } from "../walletInterface";
import { PrivateKey, AccountId, ContractExecuteTransaction, ContractId, LedgerId, TokenAssociateTransaction, TokenId, Transaction, TransactionId, TransferTransaction, Client, Hbar, PublicKey } from "@hashgraph/sdk";
import { ContractFunctionParameterBuilder } from "../contractFunctionParameterBuilder";
import { appConfig } from "../../../config";
import { SignClientTypes } from "@walletconnect/types";
import { DAppConnector, HederaJsonRpcMethod, HederaSessionEvent, HederaChainId, SignAndExecuteTransactionParams, transactionToBase64String, SignMessageParams, base64StringToUint8Array, verifySignerSignature, signatureMapToBase64String, signerSignaturesToSignatureMap } from "@hashgraph/hedera-wallet-connect";
import EventEmitter from "events";
import axios from 'axios';
import { Buffer } from 'buffer';



// Created refreshEvent because `dappConnector.walletConnectClient.on(eventName, syncWithWalletConnectContext)` would not call syncWithWalletConnectContext
// Reference usage from walletconnect implementation https://github.com/hashgraph/hedera-wallet-connect/blob/main/src/lib/dapp/index.ts#L120C1-L124C9
const refreshEvent = new EventEmitter();

// Create a new project in walletconnect cloud to generate a project id
const walletConnectProjectId = "95eb0356f1d4d9d01d2696dfe7e2fb6a";
const currentNetworkConfig = appConfig.networks.testnet;
const hederaNetwork = currentNetworkConfig.network;
const hederaClient = Client.forName(hederaNetwork);
const serverAddress = process.env.REACT_APP_SERVER_URL! || "https://nfthingy.pestnet.wethemouth.com";



const nfthingyTokenId = process.env.REACT_APP_NFTHINGY_TOKEN_ID || "0.0.4506312";
//jwt token 


const metadata: SignClientTypes.Metadata = {
  name: "NFThingy",
  description: "NFThingy to put stuff in.",
  url: window.location.origin,
  icons: [window.location.origin + "/favicon.ico"],
}
const dappConnector = new DAppConnector(
  metadata,
  LedgerId.fromString(hederaNetwork),
  walletConnectProjectId,
  Object.values(HederaJsonRpcMethod),
  [HederaSessionEvent.ChainChanged, HederaSessionEvent.AccountsChanged],
  [HederaChainId.Testnet],
);

// ensure walletconnect is initialized only once
let walletConnectInitPromise: Promise<void> | undefined = undefined;
// const initializeWalletConnect = async () => {
//   if (walletConnectInitPromise === undefined) {
//     walletConnectInitPromise = dappConnector.init();
//   }
//   await walletConnectInitPromise;
// };
// Initialize WalletConnect
const initializeWalletConnect = async () => {
  try {
    if (walletConnectInitPromise === undefined) {
      walletConnectInitPromise = dappConnector.init();
    }
    await walletConnectInitPromise;
    console.log('WalletConnect initialized successfully.');
  } catch (error) {
    console.error('Error initializing WalletConnect:', error);
  }
};



export const openWalletConnectModal = async () => {
  await initializeWalletConnect();
  await dappConnector.openModal().then((x) => {
    refreshEvent.emit("sync");
  });
};

class WalletConnectWallet implements WalletInterface {
  private get signer() {
    const signer = dappConnector.signers[0]; // This retrieves the first signer

    if (!signer) {
      console.error('Signer not available. Initialization might have failed.');
      throw new Error('Signer not available');
    }

    console.log('Signer retrieved successfully:', signer.getAccountId().toString());
    return signer;
  }

  private get accountId() {
    return AccountId.fromString(this.signer.getAccountId().toString());
  }


//mint NFThingy
async mintNfthingy() {
  console.log(this.accountId.toString)
  try {
    //request signed tx from server
    const response = await axios.post(`${serverAddress}/api/mint-nfthingy`, {
      accountId: this.accountId.toString()
    });

    const { signedTransactionBytes } = response.data;

    //deserialize signed server transaction
    const signedTx = Transaction.fromBytes(Buffer.from(signedTransactionBytes, 'base64'));

    //execute transaction with client signer
    const txResult = await signedTx.executeWithSigner(this.signer);

    return txResult ? txResult.transactionId : null;
  } catch (error) {
    console.error("Error during NFThingy mint:", error);
    throw error;
  }
}

//add Twitter auth
async addAuthTwitter(serialNumber: number, isPublic: boolean, timestamp: string) {
  try {
    //check and create unsigned tx
    const preCheckResponse = await axios.post(`${serverAddress}/api/preCheckAuthTwitter`, {
      accountId: this.accountId.toString(), 
      serialNumber, isPublic
    })
    const { signedTransactionBytes } = preCheckResponse.data;

    //deserialize signed server transaction
    const signedTx = Transaction.fromBytes(Buffer.from(signedTransactionBytes, 'base64'));

    //execute transaction with client signer
    const txResult = await signedTx.executeWithSigner(this.signer);

    if (!txResult) {
      return null;
    }

    //Send the signed transaction back to the server for final processing
    const authResponse = await axios.post(`${serverAddress}/api/addAuthTwitter`, {
      accountId: this.accountId.toString(),
      serialNumber,
      isPublic,
      timestamp, // Add timestamp here
      transactionId: txResult.transactionId.toString()
    });

    return authResponse.data.message;
  } catch (error) {
    console.error("Error adding Twitter data to NFThingy:", error);
    return null;
  }
}

//remove stuff
async removeStuff(serialNumber: number, platform: string): Promise<any> {
  try {
    // Get the signed transaction from the server
    const hbarTransactionResponse = await axios.post(`${serverAddress}/api/hbarTransaction`, {
      accountId: this.accountId.toString(), 
      type: "removeStuff",
      platform: platform,
      serialNumber: serialNumber
    });
    const { signedTransactionBytes, transactionId, nonce } = hbarTransactionResponse.data;

    // Execute the transaction with the client signer
    const signedTx = Transaction.fromBytes(Buffer.from(signedTransactionBytes, 'base64'));
    const txResult = await signedTx.executeWithSigner(this.signer);

    if (!txResult) {
      return null;
    }

    // After the transaction is executed successfully
    const response = await axios.post(`${serverAddress}/api/removeStuff`, {
      transactionId: txResult.transactionId.toString(),
      serialNumber,
      platform,
      nonce
    }, { withCredentials: true });

    return response; // Return the full response
  } catch (error) {
    console.error('Error in removeStuff:', error);
    throw error;
  }
}

  // Sign and verify message
  async signAndVerifyMessage(): Promise<string | null> {
    try {
      console.log('Sending request to /api/get-challenge'); // Log before sending request
      const challengeResponse = await axios.post(`${serverAddress}/api/get-challenge`, {
        accountId: this.accountId.toString()
      }, { withCredentials: true });

      const { message } = challengeResponse.data;
      console.log('Received challenge message:', message);

      const base64String = btoa(message);
      const messageUint8Array = base64StringToUint8Array(base64String);
      console.log('Message Uint8Array:', messageUint8Array);

      // Sign the message
      // console.log(this.signer)

      const sigMaps = await this.signer.sign([messageUint8Array]);
      console.log('Signature Maps:', sigMaps);

      // Validate the data in sigMaps
      if (sigMaps.length === 0 || !sigMaps[0].publicKey || !sigMaps[0].signature) {
        console.error('Invalid sigMaps:', sigMaps);
        return null;
      }

      // console.log('Public Key:', sigMaps[0].publicKey.toString());
      // console.log('Signature:', Array.from(sigMaps[0].signature));

      // Send signature to server for verification
      const response = await axios.post(`${serverAddress}/api/verify-signature`, {
        message: base64String,
        signature: Array.from(sigMaps[0].signature),
        publicKey: sigMaps[0].publicKey.toString(),
        accountId: this.accountId.toString()
      }, { withCredentials: true });

      if (response.data.success) {
        return response.data.token;
      } else {
        console.error('Verification failed:', response.data.message);
        return null;
      }
    } catch (error) {
      console.error('Error during sign and verify:', error);
      return null;
    }
  }



  async transferHBAR(toAddress: AccountId, amount: number) {
    console.log(this.accountId);
    const transferHBARTransaction = new TransferTransaction()
      .addHbarTransfer(this.accountId, -amount)
      .addHbarTransfer(toAddress, amount);

    await transferHBARTransaction.freezeWithSigner(this.signer);
    const txResult = await transferHBARTransaction.executeWithSigner(this.signer);
    return txResult ? txResult.transactionId : null;
  }

  async transferFungibleToken(toAddress: AccountId, tokenId: TokenId, amount: number) {
    const transferTokenTransaction = new TransferTransaction()
      .addTokenTransfer(tokenId, this.accountId, -amount)
      .addTokenTransfer(tokenId, toAddress.toString(), amount);

    await transferTokenTransaction.freezeWithSigner(this.signer);
    const txResult = await transferTokenTransaction.executeWithSigner(this.signer);
    return txResult ? txResult.transactionId : null;
  }

  async transferNonFungibleToken(toAddress: AccountId, tokenId: TokenId, serialNumber: number) {
    const transferTokenTransaction = new TransferTransaction()
      .addNftTransfer(tokenId, serialNumber, this.accountId, toAddress);

    await transferTokenTransaction.freezeWithSigner(this.signer);
    const txResult = await transferTokenTransaction.executeWithSigner(this.signer);
    return txResult ? txResult.transactionId : null;
  }

  async associateToken(tokenId: TokenId) {
    const associateTokenTransaction = new TokenAssociateTransaction()
      .setAccountId(this.accountId)
      .setTokenIds([tokenId]);

    await associateTokenTransaction.freezeWithSigner(this.signer);
    const txResult = await associateTokenTransaction.executeWithSigner(this.signer);
    return txResult ? txResult.transactionId : null;
  }

  // Purpose: build contract execute transaction and send to wallet for signing and execution
  // Returns: Promise<TransactionId | null>
  async executeContractFunction(contractId: ContractId, functionName: string, functionParameters: ContractFunctionParameterBuilder, gasLimit: number) {
    const tx = new ContractExecuteTransaction()
      .setContractId(contractId)
      .setGas(gasLimit)
      .setFunction(functionName, functionParameters.buildHAPIParams());

    await tx.freezeWithSigner(this.signer);
    const txResult = await tx.executeWithSigner(this.signer);

    // in order to read the contract call results, you will need to query the contract call's results form a mirror node using the transaction id
    // after getting the contract call results, use ethers and abi.decode to decode the call_result
    return txResult ? txResult.transactionId : null;
  }
  disconnect() {
    dappConnector.disconnectAll().then(() => {
      refreshEvent.emit("sync");
    });
  }
};
export const walletConnectWallet = new WalletConnectWallet();

// Sync WalletConnect state with the context
export const WalletConnectClient = () => {
  const { setAccountId, setIsConnected } = useContext(WalletConnectContext);

  const syncWithWalletConnectContext = useCallback(() => {
    const accountId = dappConnector.signers[0]?.getAccountId()?.toString();
    if (accountId) {
      setAccountId(accountId);
      setIsConnected(true);
    } else {
      setAccountId('');
      setIsConnected(false);
    }
  }, [setAccountId, setIsConnected]);

  useEffect(() => {
    // Sync after walletconnect finishes initializing
    refreshEvent.addListener("sync", syncWithWalletConnectContext);

    initializeWalletConnect().then(() => {
      syncWithWalletConnectContext();
    });

    return () => {
      refreshEvent.removeListener("sync", syncWithWalletConnectContext);
    }
  }, [syncWithWalletConnectContext]);
  return null;
};
