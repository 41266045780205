import React, { createContext, useState, useEffect, ReactNode, FC } from 'react';
import { jwtDecode } from 'jwt-decode';

interface AuthContextType {
  authToken: string | null;
  login: (token: string) => void;
  logout: () => void;
  isTokenValid: () => boolean;
}

const defaultAuthContext: AuthContextType = {
  authToken: null,
  login: () => {},
  logout: () => {},
  isTokenValid: () => false,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('jwtToken'));

  useEffect(() => {
    if (authToken) {
      localStorage.setItem('jwtToken', authToken);
    } else {
      localStorage.removeItem('jwtToken');
    }
  }, [authToken]);

  const login = (token: string) => {
    setAuthToken(token);
  };

  const logout = () => {
    setAuthToken(null);
  };

  const isTokenValid = () => {
    if (!authToken) return false;
    try {
      const decoded: { exp: number } = jwtDecode(authToken);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp ? decoded.exp > currentTime : false;
    } catch (error) {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout, isTokenValid }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
