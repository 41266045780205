// components/ProgressModal.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography } from '@mui/material';

const ProgressModal = ({ open, onClose, message, loading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{loading ? 'Processing...' : 'Status'}</DialogTitle>
      <DialogContent>
        {loading ? <CircularProgress /> : <Typography>{message}</Typography>}
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ProgressModal;
