import Footer from './components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from './components/Navbar';
import { Box, ThemeProvider } from '@mui/material';
import { AllWalletsProvider } from './services/wallets/AllWalletsProvider';
import AppRouter from './AppRouter';
import background from './assets/background.jpg';
import { theme } from './theme';
import "./App.css";
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AllWalletsProvider>
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              backgroundColor: '#222222',
              backgroundImage: `url(${background})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          >
            <header>
              <NavBar />
            </header>
            <Box
              flex={1}
              p={3}
            >
              <AppRouter />
            </Box>
            <Footer />
          </Box>
        </AllWalletsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
